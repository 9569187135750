<template>
  <v-expansion-panels
    v-bind="attrs"
    multiple
    v-on="{ ...$listeners }"
  >
    <v-expansion-panel>
      <v-expansion-panel-header class="d-flex justify-space-between">
        <div>
          <span class="font-weight-bold">{{ $t('global.event') }}:</span>
          {{ title }}
        </div>
        <div></div>
        <div>
        </div>
      </v-expansion-panel-header>
      <v-expansion-panel-content>
        <Flicking
          :options="{ align: 'center', circular: true, camera: '50%',panel: '50%' }"
        >
          <div
            v-for="(image, index) in images"
            :key="index"
            class="panel">
            <img
              contain
              width="200"
              height="200"
              :src="image"
            >
          </div>
        </Flicking>
        <div class="mt-8">
          <span class="font-weight-bold">{{ $t('global.detail') }}:</span>
          <p style="white-space: pre-line">
            {{ description }}
          </p>
        </div>
      </v-expansion-panel-content>
    </v-expansion-panel>
  </v-expansion-panels>
</template>
<script>
import { Flicking } from '@egjs/vue-flicking'
import '@egjs/vue-flicking/dist/flicking.css'

export default {
  components: {
    Flicking
  },
  props: {
    title: {
      type: String,
      default: ''
    },
    description: {
      type: String,
      default: ''
    },
    startTime: {
      type: String,
      default: ''
    },
    endTime: {
      type: String,
      default: ''
    },
    images: {
      type: Array,
      default: () => []
    }
  },
  computed: {
    attrs () {
      return {
        ...this.$attrs
      }
    }
  }
}
</script>
