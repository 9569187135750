<template>
  <v-container>
    <Loading v-if="isLoading" />
  </v-container>
</template>

<script>
import Loading from '../Loading.vue'

export default {
  components: { Loading },
  data () {
    return {
      isLoading: false
    }
  }
}
</script>

<style>

</style>
