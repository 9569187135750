<template>
  <v-container>
    <Loading v-if="isLoading" />
    <v-dialog
      v-model="isChooseBranch"
      persistent>
      <v-card>
        <div class="d-flex justify-center">
          <v-card-title
            class="font-weight-bold
          ">
            {{ $t('booking.chooseBranch') }}
          </v-card-title>
        </div>
        <v-form
          ref="validBranch"
        >
          <v-container class="d-flex justify-center">
            <v-row>
              <v-col cols="12">
                <v-select
                  v-model="form.branch"
                  :label="`${ $t('global.branch')}`"
                  :items="branchItems"
                  item-text="text"
                  :rules="EmptyRules"
                  item-value="value">
                </v-select>
              </v-col>
              <v-col cols="12">
                <v-select
                  v-model="form.type"
                  :label="`${ $t('global.typeBooking')}`"
                  :items="actTypeItems"
                  :rules="EmptyRules"
                  item-text="text"
                  item-value="value">
                </v-select>
              </v-col>
              <v-col cols="12">
                <v-btn
                  block
                  color="#3BAEF8"
                  dark
                  class="d-flex justify-center"
                  @click="handleBranch()">
                  {{ $t('global.submit') }}
                </v-btn>
              </v-col>
            </v-row>
          </v-container>
        </v-form>
      </v-card>
    </v-dialog>
    <ShowEventDialog
      v-model="isScheduleTest"
      :event="itemEvent"
      :date="date"
      :schedule="items"
      @close="isScheduleTest = false"
      @setTime="setTime" />
    <v-dialog v-model="isSchedule">
      <v-card>
        <v-card-title class="header justify-center mb-4">
          <span class="font-weight-bold">{{ formatDate(dateMock) }}</span>
        </v-card-title>
        <v-container>
          <div
            v-for="(item, index) in items"
            :key="index"
            class="header px-4 mb-6">
            <v-row>
              <v-col
                class="mt-1"
                cols="6">
                {{ formatSchedule(item.startTime, item.endTime) }}
              </v-col>
              <v-col
                cols="6"
              >
                <v-btn
                  v-if="item.slot >= item.max || item.isOpen === false"
                  width="120"
                  dark
                  depressed
                  color="#af5a5f">
                  {{ $t('global.full') }}
                </v-btn>
                <v-btn
                  v-else
                  width="120"
                  dark
                  depressed
                  color="#23b574"
                  @click="setTime(item.scheduleId, item.startTime, item.endTime, item.max, item.slot)">
                  {{ $t('global.blank') }} {{ item.max - item.slot }}
                </v-btn>
              </v-col>
            </v-row>
          </div>
        </v-container>
      </v-card>
    </v-dialog>
    <div v-if="isBooking">
      <Calendar
        v-if="reRender"
        :event="allEvent"
        :schedule="allSchedule"
        :fulldate="allDateFull"
        :available="allDateAvailable"
        @input="getSchedule($event)"
      />
      <div ref="booking">
        <v-row>
          <v-col
            v-if="startTime && endTime"
            cols="12">
            <v-card outlined>
              <v-card-title>
                {{ $t('booking.participantTotal') }}
              </v-card-title>
              <v-container>
                <v-form
                  ref="form"
                  lazy-validation>
                  <v-row>
                    <v-col
                      cols="6">
                      {{ $t('global.countPerson') }}
                    </v-col>
                    <v-col
                      class="pt-0"
                      cols="3">
                      <v-text-field
                        ref="participants"
                        v-model.number="participants"
                        :onkeypress="onkeypress"
                        :rules="participantRules"
                        class="py-0 my-0"
                        type="number"
                        min="1"
                      />
                    </v-col>
                    <v-col class="2">
                      {{ $t('global.person') }}
                    </v-col>
                  </v-row>
                  <div
                    v-show="isConfirm"
                    ref="details"
                    class="mt-3">
                    <v-card-title class="px-0 mx-0">
                      {{ $t('booking.bookingSummary') }}
                    </v-card-title>
                    <v-row>
                      <v-col cols="3">
                        {{ $t('global.branch') }}
                      </v-col>
                      <v-col cols="9">
                        {{ form.branch === 'trang' ? $t('global.trang') : $t('global.sathon') }}
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="3">
                        {{ $t('global.date') }}
                      </v-col>
                      <v-col cols="9">
                        <div v-if="langauge === 'th'">
                          {{ formatDate(date) }}
                        </div>
                        <div v-else>
                          {{ date | formatDateEn }}
                        </div>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="3">
                        {{ $t('global.time') }}
                      </v-col>
                      <v-col cols="9">
                        <span v-if="startTime && endTime">
                          {{ formatSchedule(startTime, endTime) }}
                        </span>
                      </v-col>
                    </v-row>
                    <v-row v-if="isMember">
                      <v-col cols="3">
                        {{ $t('global.name') }}
                      </v-col>
                      <v-col cols="9">
                        {{ profile.firstname }} {{ profile.lastname }}
                      </v-col>
                    </v-row>
                  </div>
                </v-form>
              </v-container>
            </v-card>
          </v-col>
        </v-row>
      </div>

      <div
        v-if="isConfirm"
        class="mt-10">
        <v-btn
          v-if="startTime && endTime"
          color="#23b574"
          class="mb-2"
          block
          dark
          depressed
          @click="checkConfirm">
          {{ $t('global.next') }}
        </v-btn>
        <v-btn
          v-else
          color="#23b574"
          class="mb-2 disabled"
          block
          disabled
          depressed
        >
          {{ $t('booking.nextButtonClick') }}
        </v-btn>
      </div>
      <div
        v-else
        class="mt-10">
        <v-btn
          v-if="startTime && endTime"
          color="#23b574"
          class="mb-2"
          block
          dark
          depressed
          @click="showDetail()">
          {{ $t('global.next') }}
        </v-btn>
        <v-btn
          v-else
          color="#23b574"
          class="mb-2 disabled"
          block
          disabled
          depressed
        >
          {{ $t('booking.nextButtonClick') }}
        </v-btn>
      </div>
    </div>
    <ParticipantAdd
      v-if="isParticipantAdd"
      :branch="form.branch"
      :member="profile"
      :children="children"
      :participants="participants"
      @input="onAddOrder($event)"
    />
    <BookSummary
      v-if="isSummary"
    />
  </v-container>
</template>
<script>
import { mapActions, mapGetters } from 'vuex'
import Loading from '@/components/Loading.vue'
import ParticipantAdd from '@/components/Book/ParticipantAdd.vue'
import formatDate from '@/utils/formatDate'
import Calendar from '@/components/Calendar.vue'
import ShowEventDialog from '@/components/Book/ShowEventDialog.vue'
import BookSummary from '@/components/Book/BookSummary.vue'
import axios from '../../../utils/axios.js'

export default {
  components: {
    ShowEventDialog,
    Calendar,
    ParticipantAdd,
    Loading,
    BookSummary
  },
  data () {
    return {
      isMember: false,
      isBooking: false,
      isParticipantAdd: false,
      isSummary: false,
      isScheduleTest: false,
      isSchedule: false,
      isChooseBranch: true,
      isLoading: false,
      isConfirm: false,
      validBranch: false,
      itemEvent: [],
      allEvent: [],
      backgroundColor: '#3BAEF8',
      participants: 1,
      allSchedule: [],
      allDateFull: [],
      allDateAvailable: [],
      reRender: true,
      profile: '',
      max: '',
      slot: '',
      nowDate: this.$dayjs().format('YYYY-MM-DD'),
      children: [],
      date: '',
      dateMock: '',
      startTime: '',
      endTime: '',
      form: {
        scheduleId: '',
        memberId: '',
        participant: null,
        branch: process.env.VUE_APP_BRANCH,
        type: 'normal'
      },
      items: [],
      onkeypress: 'return event.charCode >= 48 && event.charCode <= 57'
    }
  },
  computed: {
    langauge () {
      return this.$i18n.locale
    },
    EmptyRules () {
      return [(v) => !!v || this.$t('global.validate')]
    },
    branchItems () {
      return [
        { text: this.$t('global.trang'), value: 'trang' },
        { text: this.$t('global.sathon'), value: 'sathorn' }]
    },
    actTypeItems () {
      return [
        { text: this.$t('booking.actType.normal'), value: 'normal' },
        // { text: this.$t('booking.actType.allday'), value: 'allday' },
        { text: this.$t('booking.actType.group'), value: 'group' },
        { text: this.$t('booking.actType.special'), value: 'event' }
      ]
    },
    participantRules () {
      return [
        (v) => !!v || this.$t('global.validateParticipant'),
        (v) => v > 0 || this.$t('global.validateParticipant1')
      ]
    },
    ...mapGetters({
      bookingForm: 'booking/form',
      isRegistered: 'booking/isRegistered',
      participantsStore: 'booking/participants',
      group: 'booking/group',
      isMemberStore: 'booking/isMember',
      facebookId: 'webExternal/facebookId'
    })
  },
  async mounted () {
    // this.form = this.bookingForm
    this.setIsFromGroup(false)
    this.setIsFromBookingNormal(false)
    this.participants = this.participantsStore
    if (this.isRegistered) {
      this.isChooseBranch = false
      await this.checkLogined()
      this.isParticipantAdd = true
    } else if (!localStorage.getItem('member')) {
      await this.checkLogined()
    } else {
      const { member } = JSON.parse(localStorage.getItem('member'))
      this.setFacebookId(member.facebookId)
      await this.checkLogined()
    }
  },
  methods: {
    ...mapActions({
      showErrorMessage: 'notification/showErrorMessage',
      closeErrorMessage: 'notification/closeErrorMessage',
      setForm: 'booking/setForm',
      setParticipants: 'booking/setParticipants',
      setIsRegistered: 'booking/setIsRegistered',
      setLineUid: 'booking/setLineUid',
      setGroup: 'booking/setGroup',
      setIsMember: 'booking/setIsMember',
      setIsFromBookingNormal: 'booking/setIsFromBookingNormal',
      setIsFromGroup: 'booking/setIsFromGroup',
      setFacebookId: 'webExternal/setFacebookId',
      setBranch: 'booking/setBranch'
    }),
    changeLanguage (type) {
      this.$i18n.locale = type
      this.isLoading = true
      this.$nextTick(() => {
        this.isLoading = false
      })
    },
    checkConfirm () {
      const valid = this.$refs.form.validate()
      if (valid) {
        if (this.participants > this.max - this.slot) {
         this.showErrorMessage({
          type: 'warning',
          message: this.$t('booking.validateMax')
        })
        setTimeout(() => {
          this.closeErrorMessage()
        }, 4000)
         return
        }
        if (this.form.scheduleId && this.startTime && this.endTime) {
          if (this.profile) {
            this.isBooking = false
            this.isParticipantAdd = true
          } else {
            this.setIsFromBookingNormal(true)
            this.setForm(this.form)
            this.setParticipants(this.participants)
            this.$router.push('/register')
          }
        } else {
          this.showErrorMessage({
          type: 'warning',
          message: this.$t('global.validate')
        })
        setTimeout(() => {
          this.closeErrorMessage()
        }, 4000)
        }
      } else {
        this.showErrorMessage({
          type: 'warning',
          message: this.$t('global.validate')
        })
        setTimeout(() => {
          this.closeErrorMessage()
}, 4000)
      }
      // if (this.form.scheduleId && this.startTime && this.endTime && this.participants > 0) {
      //   this.isParticipantAdd = true
      // }
    },
    async getEvent () {
      try {
        const { data: { data } } = await axios.get('/event')
        this.allEvent = data
      } catch (error) {
        console.error(error)
      }
    },
    async getSchedule (event) {
      try {
        this.isLoading = true
        const { data: { data } } = await this.axios.get(`${process.env.VUE_APP_API_URL}/schedule/date/search?date=${event.date}&branch=${this.form.branch}&type=${this.form.type}`)
         const events = await axios.get(`/event/${event.date}/date?branch=${this.form.branch}`)
        // const { data: { data } } = await this.axios.get(`${process.env.VUE_APP_API_URL}/schedule/date/search?date=${event}`)
        this.items = data
        this.itemEvent = events.data.data
        this.dateMock = event.date
         if (this.form.type === 'event') {
          this.isScheduleTest = true
        } else {
          this.isSchedule = true
        }
      } catch (error) {
        this.error = error
      } finally {
        this.isLoading = false
      }
    },
    async getAllSchedule () {
      try {
        this.isLoading = true
        this.reRender = false
        const { data: { data, additional } } = await this.axios.get(`${process.env.VUE_APP_API_URL}/schedule?branch=${this.form.branch}&type=${this.form.type}`)
        this.allSchedule = data
        this.allDateFull = additional.full
        this.allDateAvailable = additional.available
      } catch (error) {
        this.error = error
      } finally {
        this.isLoading = false
        this.reRender = true
      }
    },
     async getScheduleEvent () {
      try {
        this.isLoading = true
        this.reRender = false
        const { data: { data, additional } } = await axios.get(`/schedule?branch=${this.form.branch}&type=${this.form.type}`)
        await this.getEvent()
        this.allSchedule = data
        this.allDateFull = additional.full
        this.allDateAvailable = additional.available
      } catch (error) {
        this.error = error
      } finally {
        this.isLoading = false
        this.reRender = true
      }
    },
    confirmSchedule () {
      const el = this.$refs.booking
      setTimeout(() => {
         el.scrollIntoView({
        behavior: 'smooth',
        block: 'start'
      })
      }, 200)
    this.isScheduleTest = false
    this.isSchedule = false
    },
    async onAddOrder (event) {
      try {
        this.isLoading = true
        this.form.memberId = this.profile.memberId
        this.form.participant = event
        await this.axios.post(`${process.env.VUE_APP_API_URL}/order`, this.form)

        this.setForm({
          scheduleId: '',
          memberId: '',
          participant: null,
          branch: process.env.VUE_APP_BRANCH,
          type: ''
        })
        this.setParticipants(1)
        this.setIsRegistered(false)

        this.$router.push('/history')
      } catch (error) {
        this.setForm({
          scheduleId: '',
          memberId: '',
          participant: null,
          branch: process.env.VUE_APP_BRANCH,
          type: ''
        })
        this.setParticipants(1)
        this.setIsRegistered(false)
        this.isParticipantAdd = false
        this.isBooking = true
        this.showErrorMessage({
          type: 'warning',
          message: this.$t('booking.slotMax')
        })
        setTimeout(() => {
          this.closeErrorMessage()
        }, 4000)
      } finally {
        this.isLoading = false
      }
    },
    setTime (scheduleId, startTime, endTime, max, slot) {
      this.form.scheduleId = scheduleId
      this.startTime = startTime
      this.endTime = endTime
      this.date = this.dateMock
      this.max = max
      this.slot = slot
      this.confirmSchedule()
    },
    async checkLogined () {
      try {
        const { data: { data } } = await this.axios.get(`${process.env.VUE_APP_API_URL}/member/facebook/${this.facebookId}`)
        if (!data.member) {
          this.isMember = false
          this.setIsMember(false)
          localStorage.removeItem('member')
        } else {
          localStorage.setItem('member', JSON.stringify(data))
          this.profile = data.member
          this.children = data.children
          this.isMember = true
          this.setIsMember(true)
        }
      } catch (error) {
        this.isMember = false
        this.setIsMember(false)
        localStorage.removeItem('member')
        console.error(error)
      }
    },
    handleBranch () {
      const valid = this.$refs.validBranch.validate()
      // console.log(valid)
      if (valid) {
        this.setBranch(process.env.VUE_APP_BRANCH)
        if (this.form.branch === 'sathorn') {
          this.backgroundColor = '#3BAEF8'
          this.handleActType()
        } else {
          this.backgroundColor = '#FFACB7'
          this.handleActType()
        }
      }
    },
    async handleActType () {
      try {
        if (this.form.type === 'normal') {
          await this.getAllSchedule()
          this.isChooseBranch = false
          this.isBooking = true
        }
         if (this.form.type === 'event') {
          await this.getScheduleEvent()
          this.isChooseBranch = false
          this.isBooking = true
        }
        if (this.form.type === 'group') {
          const { group } = this
          group.branch = this.form.branch
          this.setGroup(group)
          this.$router.push(`/booking/group/${this.form.branch}`)
        }
      } catch (error) {
        console.error(error)
      }
    },
    formatDate (date) {
      return formatDate.default(date)
    },
    showDetail () {
      const el = this.$refs.details
      this.isConfirm = !this.isConfirm
      setTimeout(() => {
        el.scrollIntoView({
        behavior: 'smooth',
        block: 'start'
      })
      }, 200)
    },
    formatSchedule (startTime, endTime) {
      if (startTime === '00:00' && endTime === '23:59') {
        return 'Allday'
      }
      return `${startTime} - ${endTime}`
    }
  }
}
</script>
<style lang="scss" scoped>
.header {
  background: #f4f4f4;
}
</style>
