<template>
  <v-container>
    <v-form
      ref="form"
      lazy-validation>
      <div class="text-center mb-2">
      </div>
      <v-card
        v-for="(item, index) in form"
        :key="index"
        class="px-3 py-5 ma-4"
        outlined>
        <v-row class="card-header">
          <v-col cols="12">
            <div class="d-flex justify-space-between">
              <h2>{{ $t('global.participant') }} {{ index + 1 }}</h2>
            </div>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <v-checkbox
              v-model="checkbox[index]"
              :label="`${$t('global.oldMember')}`"
              @change="changeCheckbox($event,index)"></v-checkbox>
            <v-combobox
              v-show="checkbox[index]"
              v-model="form[index].firstname"
              :items="filter"
              item-text="text"
              item-value="value"
              :label="`${$t('global.name')}`"
              @input="handleParticipantChange($event, index)"></v-combobox>
            <v-text-field
              v-show="!checkbox[index]"
              v-model="form[index].firstname"
              :rules="firstnameRules"
              :label="$t('global.name')" />
            <v-text-field
              v-model="form[index].lastname"
              :rules="lastnameRules"
              :label="$t('global.lastname')" />
            <v-text-field
              v-model="form[index].nickname"
              :label="$t('global.nickname')" />
            <v-select
              v-model="form[index].sex"
              :items="sexItems"
              item-text="text"
              item-value="value"
              :rules="sexRules"
              :label="$t('global.sex')" />
            <DateDropDown
              v-if="render"
              v-model="form[index].birthday"
              :setdefault="form[index].birthday" />
            <v-textarea
              v-model="form[index].note"
              :label="`${ $t('global.note') } (${ $t('global.foodAllergy') } ${ $t('global.or') } ${ $t('global.congenitalDisease') } )`"
              rows="2"></v-textarea>
          </v-col>
        </v-row>
      </v-card>
    </v-form>
    <div class="ma-4">
      <v-row justify="center">
        <v-col cols="12">
          <v-btn
            type="submit"
            block
            color="success"
            class="mb-2"
            depressed
            :loading="loading"
            @click="submit()">
            {{ $t('global.submit') }}
          </v-btn>
        </v-col>
      </v-row>
    </div>
  </v-container>
</template>
<script>
  import DateDropDown from '@/components/DateDropDown.vue'

  export default {
    components: {
      DateDropDown
    },
    props: {
      participants: {
        type: Number,
        required: true
      },
      member: {
        type: Object,
        required: true
      },
      children: {
        type: Array,
        required: true
      }
    },
    data () {
      return {
        loading: false,
        cloneChildren: [],
        checkbox: [],
        checkboxMember: true,
        render: true,
        participantsAll: [],
        participantsVitural: [],
        filterItem: [],
        oldIndex: null,
        form: [],
        valid: false
      }
    },
    computed: {
      filter () {
        const {
          form,
          participantsAll
        } = this
        const result = []
        for (let i = 0; i < form.length; i++) {
          for (let j = 0; j < participantsAll.length; j++) {
            if (form[i].firstname !== participantsAll[j].firstname) {
              result.push({
                text: participantsAll[j].firstname,
                value: participantsAll[j].firstname,
                index: j
              })
            }
          }
        }
        const filter = result.filter((value, index, self) => index === self.findIndex((t) => (
          t.text === value.text && t.firstname === value.firstname
        )))
        for (let i = 0, len = form.length; i < len; i++) {
          for (let j = 0, len2 = filter.length; j < len2; j++) {
            if (form[i].firstname === filter[j].text) {
              filter.splice(j, 1)
              len2 = filter.length
            }
          }
        }
        return filter
      },
      sexItems () {
        return [{
            text: this.$t('global.men'),
            value: 'male'
          },
          {
            text: this.$t('global.women'),
            value: 'female'
          },
          {
            text: this.$t('global.undefine'),
            value: 'undefine'
          }
        ]
      },
      ageItems () {
        return [{
            text: this.$t('global.adult'),
            value: 'Adult'
          },
          {
            text: this.$t('global.child'),
            value: 'Young'
          }
        ]
      },
      firstnameRules () {
        return [(v) => !!v || this.$t('global.validate')]
      },
      lastnameRules () {
        return [(v) => !!v || this.$t('global.validate')]
      },
      nicknameRules () {
        return [(v) => !!v || this.$t('global.validate')]
      },
      sexRules () {
        return [(v) => !!v || this.$t('global.validate')]
      },
      birthdayRules () {
        return [(v) => !!v || this.$t('global.validate')]
      },
      ageGroupRules () {
        return [(v) => !!v || this.$t('global.validate')]
      },
      rules () {
        return {
          required: (v) => !!v || this.$t('global.validate'),
          email: (v) => /.+@.+\..+/.test(v) || this.$t('global.validateRules.email')
        }
      }
    },
    mounted () {
      this.participantsAll.push(this.member, ...this.children)
      this.participantsVitural = this.participantsAll
      this.addform()
    },
    methods: {
      handleParticipantChange (event, index) {
          if (event) {
            this.form[index].firstname = this.participantsAll[event.index] ? this.participantsAll[event.index].firstname : this.form[index].firstname
            this.form[index].lastname = this.participantsAll[event.index] && this.participantsAll[event.index].lastname
            this.form[index].nickname = this.participantsAll[event.index] && this.participantsAll[event.index].nickname
            this.form[index].birthday = this.participantsAll[event.index] && this.participantsAll[event.index].birthday
            this.form[index].sex = this.participantsAll[event.index] && this.participantsAll[event.index].sex
            this.form[index].note = this.participantsAll[event.index] && this.participantsAll[event.index].note
          }
        this.render = false
        this.$nextTick(() => {
          this.render = true
        })
      },
      clearFormIndex (index) {
        this.form.splice(index, 1, {
          firstname: '',
          lastname: '',
          nickname: '',
          birthday: '',
          sex: 'male',
          note: ''
        })
        this.$refs.form.resetValidation()
        this.render = false
        this.$nextTick(() => {
          this.render = true
        })
      },
      addform () {
        for (let i = 0; i < this.participants; i++) {
          this.form.push({
            firstname: '',
            lastname: '',
            nickname: '',
            birthday: '',
            sex: 'male',
            note: ''
          })
          this.checkbox.push(true)
        }
      },
      submit () {
        const valid = this.$refs.form.validate()
        if (valid) {
          this.$emit('input', this.form)
        }
      },
      changeCheckbox (event, index) {
          this.form[index].firstname = ''
          this.form[index].lastname = ''
          this.form[index].nickname = ''
          this.form[index].birthday = ''
          this.form[index].sex = 'male'
          this.form[index].note = ''
          this.render = false
          this.$nextTick(() => {
            this.render = true
          })
      }
    }
  }

</script>
