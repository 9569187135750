<template>
  <v-dialog
    v-bind="attrs"
    fullscreen
    hide-overlay
    transition="dialog-bottom-transition"
    v-on="{ ...$listeners }"
  >
    <v-card>
      <v-toolbar
        color="#38AEDD"
        elevation="0"
      >
        <v-btn
          icon
          x-large
          dark
          @click="$emit('close')"
        >
          <v-icon>mdi-arrow-left-thick</v-icon>
        </v-btn>
        <v-spacer></v-spacer>
        <span
          class="white--text">{{ event[0] && event.length > 0 ? formatDate(event[0].date): '' }}</span>
      </v-toolbar>
      <v-list
        three-line
        subheader
      >
        <div v-if="event.length > 0">
          <v-subheader>{{ $t('global.event') }}</v-subheader>
          <ExpansionPanels
            v-for="(item, index) in event"
            :key="index"
            class="mt-4"
            :title="$i18n.locale === 'th' && isTextValid(item.title) ? item.title : item.titleEng"
            :start-time="item.startTime"
            :end-time="item.endTime"
            :description="$i18n.locale === 'th' && isTextValid(item.description) ? item.description : item.descriptionEng"
            :images="item.images"
          />
          <v-divider></v-divider>
        </div>
        <v-subheader>{{ $t('global.showTime') }}</v-subheader>
        <Schedule
          :schedule="schedule"
          @input="setTime($event)" />
      </v-list>
      <v-divider></v-divider>
    </v-card>
  </v-dialog>
</template>
<script>
import dayjs from 'dayjs'
import ExpansionPanels from '@/components/ExpansionPanels.vue'
import Schedule from '@/components/Book/Schedule.vue'

  export default {
    components: {
      ExpansionPanels,
      Schedule
    },
    props: {
      event: {
        type: Array,
        default: () => []
      },

      schedule: {
        type: Array,
        default: () => []
      }
    },
    data () {
      return {
        dialog: false,
        notifications: false,
        sound: true,
        widgets: false
      }
    },
    computed: {
      attrs () {
        return {
          ...this.$attrs
        }
      },
      title () {
        const language = localStorage.getItem('language')
        if (language) {
          if (language === 'th' && this.isTextValid(this.event[0].title)) {
            return this.event[0].title
          }
            return this.event[0].titleEng
        }
        return this.event[0].title
      },
      description () {
        const language = localStorage.getItem('language')
        if (language) {
          if (language === 'th' && this.isTextValid(this.event[0].description)) {
            return this.event[0].description
          }
            return this.event[0].descriptionEng
        }
        return this.event[0].description
      },
      startTime () {
        return this.event[0].startTime
      },
      endTime () {
        return this.event[0].endTime
      },
      images () {
        return this.event
      }
    },
    methods: {
      setTime ({ scheduleId, startTime, endTime, max, slot }) {
        this.$emit('setTime', scheduleId, startTime, endTime, max, slot)
      },
      formatDate (date) {
        if (this.$i18n.locale === 'th') {
          return dayjs(date).locale('th').format('dddd DD MMMM BBBB')
        }
        if (this.$i18n.locale === 'en') {
          return dayjs(date).format('dddd DD MMMM YYYY')
        }
        return ''
      },
      isTextValid (val) {
        if (val === '' || null) {
          return false
        }
        return true
      }
    }
  }
</script>
