<template>
  <div>
    <v-container>
      <div
        v-for="(item, index) in schedule"
        :key="index"
        class="header px-4 mb-6">
        <v-row>
          <v-col
            class="mt-1"
            cols="6">
            {{ formatSchedule(item.startTime, item.endTime) }}
          </v-col>
          <v-col
            cols="6"
          >
            <v-btn
              v-if="item.slot >= item.max || item.isOpen === false"
              width="120"
              dark
              depressed
              color="#af5a5f">
              {{ $t('global.full') }}
            </v-btn>
            <v-btn
              v-else
              width="120"
              dark
              depressed
              color="#23b574"
              @click="setTime(item.scheduleId, item.startTime, item.endTime, item.max, item.slot)">
              {{ $t('global.blank') }} {{ item.max - item.slot }}
            </v-btn>
          </v-col>
        </v-row>
      </div>
    </v-container>
  </div>
</template>
<script>
  export default {
    components: {
    },
    props: {
      schedule: {
        type: Array,
        default: () => []
      }
    },
    data () {
      return {
        dialog: false,
        notifications: false,
        sound: true,
        widgets: false
      }
    },
    computed: {
      attrs () {
        return {
          ...this.$attrs
        }
      }
    },
    methods: {
      setTime (scheduleId, startTime, endTime, max, slot) {
        const payload = {
          scheduleId,
          startTime,
          endTime,
          max,
          slot
        }
        this.$emit('input', payload)
      },
      formatSchedule (startTime, endTime) {
      if (startTime === '00:00' && endTime === '23:59') {
        return 'Allday'
      }
      return `${startTime} - ${endTime}`
    }
    }
  }
</script>
